import React from 'react';
import { Link } from 'react-router-dom';

import * as style from 'lib/styles';


const AuthTemplate = ({children})=>{
    return(
        <style.AuthTemplateBlock>
            <style.WhiteBox>
                <div className="logo-area">
                    <Link to="/">WELCOME TO ...</Link>
                </div>
                {children}
            </style.WhiteBox>
        </style.AuthTemplateBlock>
    );
};

export default AuthTemplate;