import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { signUp } from 'modules/auth';
import SignUpForm from 'components/auth/SignUpForm';

const SignUpContainer = ()=>{
    const dispatch = useDispatch();
    const authState = useSelector(state=>state.auth);
    console.log('auth-State : ', authState);
    const loadingState = useSelector(state=>state.loading);
    
    const onSignUp = useCallback(inputUser=>{
        console.log('onLogin : ', inputUser);
        dispatch(signUp(inputUser));
    }, [dispatch]);
    return(
        <SignUpForm 
            loadingLogin={loadingState['SIGN_UP']}
            onSignUp={onSignUp}
        />
    );
};

export default React.memo(SignUpContainer);