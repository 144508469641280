import React from 'react';
import { Route } from 'react-router-dom';

import LandingPage from 'pages/LandingPage';
import LoginPage from 'pages/LoginPage';
import SignUpPage from 'pages/SignUpPage';
import MainPage from 'pages/MainPage';

const App = ()=>{
  return (
    <div>
      <Route component={LandingPage} exact path="/" />
      <Route component={LoginPage} path="/login" />
      <Route component={SignUpPage} path="/signup" />
      <Route component={MainPage} path="/main" />
    </div>
  );
}

export default App;