import React from 'react';
import { useSelector } from 'react-redux';

import SignUpForm from 'containers/auth/SignUpContainer';
import AuthTemplate from 'components/auth/AuthTemplate';
import Dashboard from 'components/Dashboard/Dashboard';
import { Redirect } from 'react-router-dom';

const SignUpPage = ()=>{
    const isSignUp = useSelector(state=>state.auth.isSignUp);
    return(
        <div>
            {isSignUp ? <Redirect to="/login" /> : <AuthTemplate><SignUpForm /></AuthTemplate>}
        </div>
    )
};

export default SignUpPage;