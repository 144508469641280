import { call, put } from 'redux-saga/effects';
import { startLoading, finishLoading } from 'modules/loading';

const createRqSaga = (type, request)=>{
    console.log('saga-request : ', request);
    const SUCCESS = `${type}_SUCCESS`;
    const FAILURE = `${type}_FAILURE`;

    return function*(action){
        console.log('saga action : ', action);
        console.log('saga type : ', type);
        yield put(startLoading(type));
    
        try {
            //temp test
            switch (type){
                case 'auth/TEMP':
                  const res = yield call(request, action.payload);  
                    //console.log('response : ', res);
                    yield put({
                        type: SUCCESS,
                        payload: {
                            temp: res,
                            downCompleted: true,
                        }
                    });        
                    break;
                default:
                    const response = yield call(request, action.payload.idNum);
                    yield put({
                        type: SUCCESS,
                        payload: {
                            id: action.payload.id,
                            pw: action.payload.pw,
                            rcv: response.data,
                            isLogined: true,
                            isSignUp: true,
                        }
                    });        
                    break;
            }

        } catch(e) {
            yield put({
                type: FAILURE,
                payload: {error: e},
                error: true
            });
        }
        yield put(finishLoading(type));
    };
};

export default createRqSaga;