import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import GitHubIcon from '@material-ui/icons/GitHub';
import FacebookIcon from '@material-ui/icons/Facebook';
import TwitterIcon from '@material-ui/icons/Twitter';
import Header from './Header';
import MainFeaturedPost from './MainFeaturedPost';
import FeaturedPost from './FeaturedPost';
import Main from './Main';
import Sidebar from './Sidebar';
import Footer from './Footer';
import post1 from './blog-post.1.md.js';
import post2 from './blog-post.2.md.js';
import post3 from './blog-post.3.md.js';

//import ams_landing_img_small from '../../assets/images/ams-landing-img-small.webp';

const useStyles = makeStyles((theme) => ({
  mainGrid: {
    marginTop: theme.spacing(3),
  },
}));

const sections = [
  { title: 'Home', url: '#' },
  { title: 'Overview', url: '#' },
  { title: 'WorkFlow', url: '#' },
  { title: 'Demo', url: '#' },
  { title: 'Contact Us', url: '#' },
];

const mainFeaturedPost = {
  title: 'ASSET MANAGEMENT SYSTEM',
  description:
    "Asset Management System enables you to manage hardware, software and licenses assets easily, get your all stuffs organized, provide support through simple console managemnt tool.",
  image: '/images/ams-landing-img-small.webp',
  //image: ams_landing_img_small,
  //image: '/images/ams-landing-img-small.webp',
  imgText: 'main image description',
  linkText: 'Continue reading…',
};

const featuredPosts = [
  {
    title: 'NEW OPEN AMS Portal',
    date: 'Nov. 30th 2020',
    description:
      'Assets Management Portal serivce is now available from 30th November, then which make your burden less than your finger touch',
    image: '/images/ams-landing-img-post-1.webp',
    imageText: 'Image Text',
  },
  {
    title: 'FREE EVENT for New Register',
    date: 'Dec. 1st 2020',
    description:
      'Big Event for very first new Register for the AMS protal service, who can get the free serivce for 1 year as Free Tier and the more valuable things',
    image: '/images/ams-landing-img-post-2.webp',
    imageText: 'Image Text',
  },
];

const posts = [post1, post2, post3];

const sidebar = {
  title: 'Contact',
  description:
    'Email : tlsdn@gsitm.com / +82 010-5550-7169',
  archives: [
    { title: 'March 2020', url: '#' },
    { title: 'February 2020', url: '#' },
    { title: 'January 2020', url: '#' },
    { title: 'November 2020', url: '#' },
    { title: 'October 2019', url: '#' },
    { title: 'September 2019', url: '#' },
    { title: 'August 2019', url: '#' },
    { title: 'July 2019', url: '#' },
    { title: 'June 2019', url: '#' },
    { title: 'May 2019', url: '#' },
    { title: 'April 2019', url: '#' },
  ],
  social: [
    { name: 'GitHub', icon: GitHubIcon },
    { name: 'Twitter', icon: TwitterIcon },
    { name: 'Facebook', icon: FacebookIcon },
  ],
};

export default function Blog() {
  const classes = useStyles();

  return (
    <React.Fragment>
      <CssBaseline />
      <Container maxWidth="lg">
        <Header title="Aseet Management System" sections={sections} />
        <main>
          <MainFeaturedPost post={mainFeaturedPost} />
          <Grid container spacing={4}>
            {featuredPosts.map((post) => (
              <FeaturedPost key={post.title} post={post} />
            ))}
          </Grid>
          <Grid container spacing={5} className={classes.mainGrid}>
            <Main title="ASM Features" posts={posts} />
            <Sidebar
              title={sidebar.title}
              description={sidebar.description}
              archives={sidebar.archives}
              social={sidebar.social}
            />
          </Grid>
        </main>
      </Container>
      <Footer title="About" description="Asset Management System by" />
    </React.Fragment>
  );
}