import React from 'react';
import { useSelector } from 'react-redux';

import Dashboard from 'components/Dashboard/Dashboard';
import { Redirect } from 'react-router-dom';

const MainPage = ()=>{
/*
    const isLogined = useSelector(state=>state.auth.isLogined);
    return(
        <div>
            {isLogined ? <Dashboard /> : <Redirect to="/login" />}
        </div>
    )
*/
    return(
        <div>
            <Dashboard />
        </div>
    )
};

export default MainPage;