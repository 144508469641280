import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ListSubheader from '@material-ui/core/ListSubheader';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import VerticalSplit from '@material-ui/icons/VerticalSplit';
import DraftsIcon from '@material-ui/icons/Drafts';
import Receipt from '@material-ui/icons/Receipt';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import PostAdd from '@material-ui/icons/PostAdd';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
}));

export default function NestedList() {
  const classes = useStyles();
  const [openOne, setOpenOne] = React.useState(false);
  const [openTwo, setOpenTwo] = React.useState(false);

  const handleClickOne = () => {
    setOpenOne(!openOne);
  };
  const handleClickTwo = () => {
    setOpenTwo(!openTwo);
  };

  return (
    <List
      component="nav"
      aria-labelledby="nested-list-subheader"
      subheader={
        <ListSubheader component="div" id="nested-list-subheader">
          Asset Reports
        </ListSubheader>
      }
      className={classes.root}
    >
      <ListItem button onClick={handleClickOne}>
        <ListItemIcon>
          <Receipt />
        </ListItemIcon>
        <ListItemText primary="자산 보고서" />
        {openOne ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={openOne} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <ListItem button className={classes.nested}>
            <ListItemIcon>
              <PostAdd />
            </ListItemIcon>
            <ListItemText primary="정기 자산 현황 보고서" />
          </ListItem>
          <ListItem button className={classes.nested}>
            <ListItemIcon>
              <PostAdd />
            </ListItemIcon>
            <ListItemText primary="년 간 현황 보고서" />
          </ListItem>
          <ListItem button className={classes.nested}>
            <ListItemIcon>
              <PostAdd />
            </ListItemIcon>
            <ListItemText primary="월 간 현황 보고서" />
          </ListItem>
          <ListItem button className={classes.nested}>
            <ListItemIcon>
              <PostAdd />
            </ListItemIcon>
            <ListItemText primary="품목 별 현황 보고서" />
          </ListItem>
          <ListItem button className={classes.nested}>
            <ListItemIcon>
              <PostAdd />
            </ListItemIcon>
            <ListItemText primary="직군 별 현황 보고서" />
          </ListItem>
          <ListItem button className={classes.nested}>
            <ListItemIcon>
              <PostAdd />
            </ListItemIcon>
            <ListItemText primary="조직 별 현황 보고서" />
          </ListItem>
          <ListItem button className={classes.nested}>
            <ListItemIcon>
              <PostAdd />
            </ListItemIcon>
            <ListItemText primary="매입 업체 별 현황 보고서" />
          </ListItem>
          <ListItem button className={classes.nested}>
            <ListItemIcon>
              <PostAdd />
            </ListItemIcon>
            <ListItemText primary="견적 요청 현황 보고서" />
          </ListItem>
          <ListItem button className={classes.nested}>
            <ListItemIcon>
              <PostAdd />
            </ListItemIcon>
            <ListItemText primary="발주 요청 현황 보고서" />
          </ListItem>
        </List>
      </Collapse>

      <ListItem button onClick={handleClickTwo}>
        <ListItemIcon>
          <VerticalSplit />
        </ListItemIcon>
        <ListItemText primary="자산 통계" />
        {openTwo ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={openTwo} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <ListItem button className={classes.nested}>
            <ListItemIcon>
              <PostAdd />
            </ListItemIcon>
            <ListItemText primary="년 간 현황" />
          </ListItem>
          <ListItem button className={classes.nested}>
            <ListItemIcon>
              <PostAdd />
            </ListItemIcon>
            <ListItemText primary="월 간 현황" />
          </ListItem>
          <ListItem button className={classes.nested}>
            <ListItemIcon>
              <PostAdd />
            </ListItemIcon>
            <ListItemText primary="품목 별 현황" />
          </ListItem>
          <ListItem button className={classes.nested}>
            <ListItemIcon>
              <PostAdd />
            </ListItemIcon>
            <ListItemText primary="직군 별 현황" />
          </ListItem>
          <ListItem button className={classes.nested}>
            <ListItemIcon>
              <PostAdd />
            </ListItemIcon>
            <ListItemText primary="조직 별 현황" />
          </ListItem>
          <ListItem button className={classes.nested}>
            <ListItemIcon>
              <PostAdd />
            </ListItemIcon>
            <ListItemText primary="품목 별 재고 현황" />
          </ListItem>
          <ListItem button className={classes.nested}>
            <ListItemIcon>
              <PostAdd />
            </ListItemIcon>
            <ListItemText primary="매입 업체 별 현황" />
          </ListItem>
          <ListItem button className={classes.nested}>
            <ListItemIcon>
              <PostAdd />
            </ListItemIcon>
            <ListItemText primary="견적 요청 현황" />
          </ListItem>
          <ListItem button className={classes.nested}>
            <ListItemIcon>
              <PostAdd />
            </ListItemIcon>
            <ListItemText primary="발주 요청 현황" />
          </ListItem>
        </List>
      </Collapse>
    </List>
  );
}