import React from 'react';
import { useSelector } from 'react-redux';

import LoginForm from 'containers/auth/LoginContainer';
import AuthTemplate from 'components/auth/AuthTemplate';
import Dashboard from 'components/Dashboard/Dashboard';
import { Redirect } from 'react-router-dom';

const LoginPage = ()=>{
    const isLogined = useSelector(state=>state.auth.isLogined);
    return(
        <div>
            {isLogined ? <Redirect to="/main" /> : <AuthTemplate><LoginForm /></AuthTemplate>}
        </div>
    )
};

export default LoginPage;