import React from 'react';
import PropTypes from 'prop-types';
import { withStyles, makeStyles, useTheme } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';
import AssetListSearch from './AssetListSearch';

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const useStyles1 = makeStyles((theme) => ({
  root: {
    flexShrink: 0,
    marginLeft: theme.spacing(2.5),
  },
}));

function TablePaginationActions(props) {
  const classes = useStyles1();
  const theme = useTheme();
  const { count, page, rowsPerPage, onChangePage } = props;

  const handleFirstPageButtonClick = (event) => {
    onChangePage(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onChangePage(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onChangePage(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <div className={classes.root}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </div>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onChangePage: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

function createData(code, category, name, standard, model, serialNum, company, price, date, remain) {
    return {code, category, name, standard, model, serialNum, company, price, date, remain};
}
  
const rows = [
    createData('GSITM-ECT1910001','유형자산','기타','SmartPhone', 'iPhone6 16GB',             'GSITM-CF-001','Apple',     '1,155,000',    '10/20/2019','1,090,834'),
    createData('GSITM-ECT1910002','유형자산','기타','SmartPhone', 'iPhone6 * 16GB',           'GSITM-CF-002','Apple',     '1,485,000',    '10/20/2019','1,237,500'),
    createData('GSITM-ECT1910003','유형자산','기타','SmartPhone', 'Galaxy S5SM-16GB',  'GSITM-CF-003','Samsung',   '753,500',      '10/23/2019','711,638'),
    createData('GSITM-ECT1910004','유형자산','기타','SmartPhone', 'Galaxy note4 32GB',  'GSITM-CF-004','Samsung',   '1,116,500',    '10/23/2019','1,054,472'),
    createData('GSITM-ECT1910005','유형자산','기타','SmartPhone', 'LG G3 D855 32GB',          'GSITM-CF-005','LG',        '764,500',      '10/23/2019','722,028'),
    createData('GSITM-ECT1910006','유형자산','기타','SmartPhone', 'iPhone6 * 128GB',          'GSITM-CF-006','Apple',     '1,760,000',    '10/31/2019','1,662,222'),
    createData('GSITM-ECT1911001','유형자산','기타','SmartPhone', 'iPhone6 64GB',             'GSITM-CF-007','Apple',     '1,001,000',    '11/12/2019','945,388'),
    createData('GSITM-ECT1912001','유형자산','기타','TV Stand',   'Bracket BLS-015',          'GSITM-CF-008','DaiJa',     '387,200',      '12/03/2019','354,933'),
    createData('GSITM-ECT1912002','유형자산','기타','SmartPhone', 'iPhone6 * 16GB',           'GSITM-CF-009','Apple',     '913,000',      '12/03/2019','887,639'),
    createData('GSITM-ECT1912003','유형자산','기타','SmartPhone', 'Galaxy note4 32GB',  'GSITM-CF-010','Samsung',   '1,089,000',    '12/03/2019','1,058,750'),
    createData('GSITM-ECT1910001','유형자산','기타','SmartPhone', 'iPhone6 16GB',             'GSITM-CF-001','Apple',     '1,155,000',    '10/20/2019','1,090,834'),
    createData('GSITM-ECT1910002','유형자산','기타','SmartPhone', 'iPhone6 * 16GB',           'GSITM-CF-002','Apple',     '1,485,000',    '10/20/2019','1,237,500'),
    createData('GSITM-ECT1910003','유형자산','기타','SmartPhone', 'Galaxy S5SM-16GB',  'GSITM-CF-003','Samsung',   '753,500',      '10/23/2019','711,638'),
    createData('GSITM-ECT1910004','유형자산','기타','SmartPhone', 'Galaxy note4 32GB',  'GSITM-CF-004','Samsung',   '1,116,500',    '10/23/2019','1,054,472'),
    createData('GSITM-ECT1910005','유형자산','기타','SmartPhone', 'LG G3 D855 32GB',          'GSITM-CF-005','LG',        '764,500',      '10/23/2019','722,028'),
    createData('GSITM-ECT1910006','유형자산','기타','SmartPhone', 'iPhone6 * 128GB',          'GSITM-CF-006','Apple',     '1,760,000',    '10/31/2019','1,662,222'),
    createData('GSITM-ECT1911001','유형자산','기타','SmartPhone', 'iPhone6 64GB',             'GSITM-CF-007','Apple',     '1,001,000',    '11/12/2019','945,388'),
    createData('GSITM-ECT1912001','유형자산','기타','TV Stand',   'Bracket BLS-015',          'GSITM-CF-008','DaiJa',     '387,200',      '12/03/2019','354,933'),
    createData('GSITM-ECT1912002','유형자산','기타','SmartPhone', 'iPhone6 * 16GB',           'GSITM-CF-009','Apple',     '913,000',      '12/03/2019','887,639'),
    createData('GSITM-ECT1912003','유형자산','기타','SmartPhone', 'Galaxy note4 32GB',  'GSITM-CF-010','Samsung',   '1,089,000',    '12/03/2019','1,058,750'),
    createData('GSITM-ECT1910001','유형자산','기타','SmartPhone', 'iPhone6 16GB',             'GSITM-CF-001','Apple',     '1,155,000',    '10/20/2019','1,090,834'),
    createData('GSITM-ECT1910002','유형자산','기타','SmartPhone', 'iPhone6 * 16GB',           'GSITM-CF-002','Apple',     '1,485,000',    '10/20/2019','1,237,500'),
    createData('GSITM-ECT1910003','유형자산','기타','SmartPhone', 'Galaxy S5SM-16GB',  'GSITM-CF-003','Samsung',   '753,500',      '10/23/2019','711,638'),
    createData('GSITM-ECT1910004','유형자산','기타','SmartPhone', 'Galaxy note4 32GB',  'GSITM-CF-004','Samsung',   '1,116,500',    '10/23/2019','1,054,472'),
    createData('GSITM-ECT1910005','유형자산','기타','SmartPhone', 'LG G3 D855 32GB',          'GSITM-CF-005','LG',        '764,500',      '10/23/2019','722,028'),
    createData('GSITM-ECT1910006','유형자산','기타','SmartPhone', 'iPhone6 * 128GB',          'GSITM-CF-006','Apple',     '1,760,000',    '10/31/2019','1,662,222'),
    createData('GSITM-ECT1911001','유형자산','기타','SmartPhone', 'iPhone6 64GB',             'GSITM-CF-007','Apple',     '1,001,000',    '11/12/2019','945,388'),
    createData('GSITM-ECT1912001','유형자산','기타','TV Stand',   'Bracket BLS-015',          'GSITM-CF-008','DaiJa',     '387,200',      '12/03/2019','354,933'),
    createData('GSITM-ECT1912002','유형자산','기타','SmartPhone', 'iPhone6 * 16GB',           'GSITM-CF-009','Apple',     '913,000',      '12/03/2019','887,639'),
    createData('GSITM-ECT1912003','유형자산','기타','SmartPhone', 'Galaxy note4 32GB',  'GSITM-CF-010','Samsung',   '1,089,000',    '12/03/2019','1,058,750'),
    createData('GSITM-ECT1910001','유형자산','기타','SmartPhone', 'iPhone6 16GB',             'GSITM-CF-001','Apple',     '1,155,000',    '10/20/2019','1,090,834'),
    createData('GSITM-ECT1910002','유형자산','기타','SmartPhone', 'iPhone6 * 16GB',           'GSITM-CF-002','Apple',     '1,485,000',    '10/20/2019','1,237,500'),
    createData('GSITM-ECT1910003','유형자산','기타','SmartPhone', 'Galaxy S5SM-16GB',  'GSITM-CF-003','Samsung',   '753,500',      '10/23/2019','711,638'),
    createData('GSITM-ECT1910004','유형자산','기타','SmartPhone', 'Galaxy note4 32GB',  'GSITM-CF-004','Samsung',   '1,116,500',    '10/23/2019','1,054,472'),
    createData('GSITM-ECT1910005','유형자산','기타','SmartPhone', 'LG G3 D855 32GB',          'GSITM-CF-005','LG',        '764,500',      '10/23/2019','722,028'),
    createData('GSITM-ECT1910006','유형자산','기타','SmartPhone', 'iPhone6 * 128GB',          'GSITM-CF-006','Apple',     '1,760,000',    '10/31/2019','1,662,222'),
    createData('GSITM-ECT1911001','유형자산','기타','SmartPhone', 'iPhone6 64GB',             'GSITM-CF-007','Apple',     '1,001,000',    '11/12/2019','945,388'),
    createData('GSITM-ECT1912001','유형자산','기타','TV Stand',   'Bracket BLS-015',          'GSITM-CF-008','DaiJa',     '387,200',      '12/03/2019','354,933'),
    createData('GSITM-ECT1912002','유형자산','기타','SmartPhone', 'iPhone6 * 16GB',           'GSITM-CF-009','Apple',     '913,000',      '12/03/2019','887,639'),
    createData('GSITM-ECT1912003','유형자산','기타','SmartPhone', 'Galaxy note4 32GB',  'GSITM-CF-010','Samsung',   '1,089,000',    '12/03/2019','1,058,750'),
];

const useStyles2 = makeStyles({
  table: {
    minWidth: 500,
  },
});

export default function AssetList() {
  const classes = useStyles2();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <TableContainer component={Paper}>
      <AssetListSearch />
      <Table className={classes.table} aria-label="custom pagination table">
      <TableHead>
          <TableRow>
            <StyledTableCell width="19%" >자산코드</StyledTableCell>
            <StyledTableCell width="11%" align="right">자산품목</StyledTableCell>
            <StyledTableCell width="8%" align="right">자산명</StyledTableCell>
            <StyledTableCell width="8%" align="right">자산규격</StyledTableCell>
            <StyledTableCell width="19%" align="right">모델명</StyledTableCell>
            <StyledTableCell width="15%" align="right">Serial Number</StyledTableCell>
            <StyledTableCell width="5%" align="right">제조업체</StyledTableCell>
            <StyledTableCell width="5%" align="right">구입단가</StyledTableCell>
            <StyledTableCell width="5%" align="right">취득일</StyledTableCell>
            <StyledTableCell width="5%" align="right">잔존가액</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {(rowsPerPage > 0
            ? rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            : rows
          ).map((row) => (
            <StyledTableRow key={row.name}>
              <StyledTableCell component="th" scope="row">
                {row.code}
              </StyledTableCell>
              <StyledTableCell align="right">{row.category}</StyledTableCell>
              <StyledTableCell align="right">{row.name}</StyledTableCell>
              <StyledTableCell align="right">{row.standard}</StyledTableCell>
              <StyledTableCell align="right">{row.model}</StyledTableCell>
              <StyledTableCell align="right">{row.serialNum}</StyledTableCell>
              <StyledTableCell align="right">{row.company}</StyledTableCell>
              <StyledTableCell align="right">{row.price}</StyledTableCell>
              <StyledTableCell align="right">{row.date}</StyledTableCell>
              <StyledTableCell align="right">{row.remain}</StyledTableCell>
            </StyledTableRow>
          ))}

          {emptyRows > 0 && (
            <TableRow style={{ height: 53 * emptyRows }}>
              <TableCell colSpan={6} />
            </TableRow>
          )}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
              colSpan={3}
              count={rows.length}
              rowsPerPage={rowsPerPage}
              page={page}
              SelectProps={{
                inputProps: { 'aria-label': 'rows per page' },
                native: true,
              }}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
              ActionsComponent={TablePaginationActions}
            />
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>  
  );
}