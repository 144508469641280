import React from 'react';
import { useTheme } from '@material-ui/core/styles';
//import { LineChart, Line, XAxis, YAxis, Label, ResponsiveContainer } from 'recharts';
import { BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

import Title from './Title';

// Generate Sales Data
function createData(time, amount) {
  return { time, amount };
}
/*
const data = [
  createData('00:00', 0),
  createData('03:00', 300),
  createData('06:00', 600),
  createData('09:00', 800),
  createData('12:00', 1500),
  createData('15:00', 2000),
  createData('18:00', 2400),
  createData('21:00', 2400),
  createData('24:00', undefined),
];
*/
const data = [
  {name: '1월', amount: 4000, unit: 2400,},
  {name: '2월', amount: 3000, unit: 1398,},
  {name: '3월', amount: 2000, unit: 9800,},
  {name: '4월', amount: 2780, unit: 3908,},
  {name: '5월', amount: 1890, unit: 4800,},
  {name: '6월', amount: 2390, unit: 3800,},
  {name: '7월', amount: 3490, unit: 4300,},
  {name: '8월', amount: 4000, unit: 2400,},
  {name: '9월', amount: 3000, unit: 1398,},
  {name: '10월', amount: 2000, unit: 9800,},
  {name: '11월', amount: 2780, unit: 3908,},
  {name: '12월', amount: 0, unit: 0,},
];
export default function Chart() {
  const theme = useTheme();
  return(
    <React.Fragment>
      <Title>연간 구매 현황</Title>
      <ResponsiveContainer>
        <BarChart width={730} height={250} data={data}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip />
          <Legend />
          <Bar dataKey="unit" fill="#8884d8" />
          <Bar dataKey="amount" fill="#82ca9d" />
        </BarChart>
      </ResponsiveContainer>
    </React.Fragment>
  )
  /*
  return (
    <React.Fragment>
      <Title>Today</Title>
      <ResponsiveContainer>
        <LineChart
          data={data}
          margin={{
            top: 16,
            right: 16,
            bottom: 0,
            left: 24,
          }}
        >
          <XAxis dataKey="time" stroke={theme.palette.text.secondary} />
          <YAxis stroke={theme.palette.text.secondary}>
            <Label
              angle={270}
              position="left"
              style={{ textAnchor: 'middle', fill: theme.palette.text.primary }}
            >
              Sales ($)
            </Label>
          </YAxis>
          <Line type="monotone" dataKey="amount" stroke={theme.palette.primary.main} dot={false} />
        </LineChart>
      </ResponsiveContainer>
    </React.Fragment>
  );
  */
}