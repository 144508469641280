import React from 'react';
import Landing from 'components/Landing/Blog';

const LandingPage = ()=>{
    return(
        <div>
            <Landing />
        </div>
    );
};

export default LandingPage;