import { combineReducers } from 'redux';
import auth, { authSaga } from './auth';
import loading from 'modules/loading';
import { all } from 'redux-saga/effects';

const rootRedcuer = combineReducers({
    auth,
    loading,
});

export function* rootSaga(){
    yield all([authSaga()]);
}

export default rootRedcuer;