import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';
import Title from './Title';

const _code = [
  {value: 'GSITM-ECT1910001', label: 'GSITM-ECT1910001',},
  {value: 'GSITM-ECT1910002', label: 'GSITM-ECT1910002',},
  {value: 'GSITM-ECT1910003', label: 'GSITM-ECT1910003',},
  {value: 'GSITM-ECT1910004', label: 'GSITM-ECT1910004',},
];
const _category = [
  {value: '유형자산', label: '유형자산',},
  {value: '무형자산', label: '무형자산',},
  {value: '기타자산', label: '기타자산',},
];
const _standard = [
  {value: 'SmartPhone', label: 'SmartPhone',},
  {value: 'TV Stand', label: 'TV Stand',},
  {value: 'Computer', label: 'Computer',},
  {value: 'NoteBook', label: 'NoteBook',},
];
const _serialNumber = [
  {value: 'GSITM-CF-001', label: 'GSITM-CF-001',},
  {value: 'GSITM-CF-002', label: 'GSITM-CF-002',},
  {value: 'GSITM-CF-003', label: 'GSITM-CF-003',},
  {value: 'GSITM-CF-004', label: 'GSITM-CF-004',},
];
const _company = [
  {value: 'Apple', label: 'Apple',},
  {value: 'Samsung', label: 'Samsung',},
  {value: 'LG', label: 'LG',},
  {value: 'HP', label: 'Hp',},
  {value: 'Dell', label: 'Dell',},
  {value: 'Oracle', label: 'Oracle',},
];

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      width: '25ch',
    },
  },
}));

const AssetListSearch = ()=>{
  const classes = useStyles();
  const [code, setCode] = useState('GSITM-ECT1910001');
  const [category, setCategory] = useState('유형자산');
  const [standard, setStandard] = useState('SmartPhone');
  const [serialNumber, setSerialNumber] = useState('GSITM-CF-001');
  const [company, setCompany] = useState('Apple');
  
  const handleChangeCode = (event) => {setCode(event.target.value);};
  const handleChangeCategory = (event) => {setCategory(event.target.value);};
  const handleChangeStandard = (event) => {setStandard(event.target.value);};
  const handleChangeSerialNumber = (event) => {setSerialNumber(event.target.value);};
  const handleChangeCompany = (event) => {setCompany(event.target.value);};

  return (
    <form className={classes.root} noValidate autoComplete="off">
      <div>
        <Title>Asset List</Title>
        <TextField
          id="filled-select-currency-native"
          select
          label="자산코드"
          value={code}
          onChange={handleChangeCode}
          SelectProps={{
            native: true,
          }}
          helperText="Please select code"
          variant="filled"
        >
          {_code.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
        <TextField
          id="outlined-select-currency"
          select
          label="자산품목"
          value={category}
          onChange={handleChangeCategory}
          SelectProps={{
            native: true,
          }}
          helperText="Please select category"
          variant="outlined"
        >
          {_category.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </TextField>
        <TextField
          id="outlined-select-currency"
          select
          label="자산규격"
          value={standard}
          onChange={handleChangeStandard}
          helperText="Please select standard"
          variant="outlined"
        >
          {_standard.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
        <TextField
          id="outlined-select-currency"
          select
          label="Serial Number"
          value={serialNumber}
          onChange={handleChangeSerialNumber}
          SelectProps={{
            native: true,
          }}
          helperText="Please select Serial Number"
          variant="outlined"
        >
          {_serialNumber.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </TextField>
        <TextField
          id="outlined-select-currency"
          select
          label="제조업체"
          value={company}
          onChange={handleChangeCategory}
          SelectProps={{
            native: true,
          }}
          helperText="Please select company"
          variant="outlined"
        >
          {_company.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </TextField>
      </div>
    </form>
  );
}

export default AssetListSearch;