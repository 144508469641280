import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { login, temp } from 'modules/auth';
import LoginForm from 'components/auth/LoginForm';

const LoginContainer = ()=>{
    const dispatch = useDispatch();
    const authState = useSelector(state=>state.auth);
    const loadingState = useSelector(state=>state.loading);
    
    /*
    const onLogin = useCallback(inputUser=>{
        dispatch(login(inputUser));
    }, [dispatch]);
    */
    const onLogin = (inputUser=>{
        if(inputUser.id === 'admin@ams.monboard.com' && inputUser.pw === 'amsadmin2020'){
            dispatch(login(inputUser));
        }
    });
    return(
        <LoginForm 
            loadingLogin={loadingState['LOG_IN']}
                onLogin={onLogin}
        />
    );
};

export default React.memo(LoginContainer);