import React, { useState } from 'react';
import Link from '@material-ui/core/Link';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Title from './Title';
import Description from '@material-ui/icons/Description';
import Modal  from '@material-ui/core/Modal';

// Generate Order Data
function createData(id, p_date, p_company, p_asset, p_unit, p_amount, p_order) {
  return { id, p_date, p_company, p_asset, p_unit, p_amount, p_order };
}

const rows = [
  createData(0, '2020년 10월 8일', '삼성전자 디지털 부문', 'Memory 128GB DDRM', 120, '160,000,000', 'ClickHere' ),
  createData(1, '2020년 10월 17일', 'LG전자 컴퓨터사업부', 'Gram Notebook', 50, '65,000,000', 'ClickHere' ),
  createData(2, '2020년 10월 24일', '오라클 유한회사', 'Oracle 12c', 10, '350,000,000', 'ClickHere' ),
  createData(3, '2020년 11월 15일', 'HP Computer', 'HP DL380 Gen10', 5, '78,000,000', 'ClickHere' ),
  createData(4, '2020년 11월 24일', '한국 IBM Corp.', 'IBM PowerSystme E980', 8, '760,000,000', 'ClickHere' ),
];

function preventDefault(event) {
  event.preventDefault();
}

const useStyles = makeStyles((theme) => ({
  seeMore: {
    marginTop: theme.spacing(3),
  },
}));

export default function Orders() {
  const classes = useStyles();
  return (
    <React.Fragment>
      <Title>Recent Orders</Title>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell align="center">No.</TableCell>
            <TableCell align="center">구매 일자</TableCell>
            <TableCell align="center">구매 회사</TableCell>
            <TableCell align="center">구매 품목</TableCell>
            <TableCell align="right">구매 수량</TableCell>
            <TableCell align="right">구매 금액</TableCell>
            <TableCell align="right">견적서</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow key={row.id}>
              <TableCell align="center">{row.id+1}</TableCell>
              <TableCell align="center">{row.p_date}</TableCell>
              <TableCell align="center">{row.p_company}</TableCell>
              <TableCell align="center">{row.p_asset}</TableCell>
              <TableCell align="right">{row.p_unit}</TableCell>
              <TableCell align="right">{row.p_amount}</TableCell>
              <TableCell align="right"><Description /></TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <div className={classes.seeMore}>
        <Link color="primary" href="#" onClick={preventDefault}>
          See more orders
        </Link>
      </div>
    </React.Fragment>
  );
}