import { createAction, handleActions } from 'redux-actions';
import { takeLatest } from 'redux-saga/effects';
import produce from 'immer';

import * as api from 'lib/api/auth';
import createRqSaga from 'lib/saga';

const LOG_IN = 'auth/LOG_IN';
const SIGN_UP = 'auth/SIGN_UP';
const LOG_IN_SUCCESS = 'auth/LOG_IN_SUCCESS';
const SIGN_UP_SUCCESS = 'auth/SIGN_UP_SUCCESS';

export const login = createAction(LOG_IN, inputUser=>inputUser);
export const signUp = createAction(SIGN_UP, inputUser=>inputUser);

const loginSaga = createRqSaga(LOG_IN, api.getPost);
const signUpSaga = createRqSaga(SIGN_UP, api.getUser);

export function* authSaga(){
    yield takeLatest(LOG_IN, loginSaga);
    yield takeLatest(SIGN_UP, signUpSaga);
}

const initState = {
    inputLogin: {
        id: null, pw: null
    },
    inputSignUp: {
        id: null, email: null, pw: null, cfmPw: null
    },
    rcvLogin: {},
    rcvSignUp: null,
    isLogined: false,
    isSignUp: false,
};

const auth = handleActions({
    [LOG_IN_SUCCESS]: (state, action)=>{
        return {
            ...state,
            inputLogin: {
                id: action.payload.id,
                pw: action.payload.pw
            },
            rcvLogin: action.payload.rcv,
            isLogined: action.payload.isLogined,
        };
    },
    [SIGN_UP_SUCCESS]: (state, action)=>{
        return {
            ...state,
            inputSignUp: {
                id: action.payload.id,
                email: action.payload.email,
                pw: action.payload.pw
            },
            rcvSignUp: action.payload.rcv,
            isSignUp: action.payload.isSignUp,
        };
    },
}, initState);

export default auth;