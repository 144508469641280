import React from 'react';
import FusionCharts from "fusioncharts";
import charts from "fusioncharts/fusioncharts.charts";
import ReactFusioncharts from "react-fusioncharts";

import Link from '@material-ui/core/Link';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Title from './Title';

function preventDefault(event) {
    event.preventDefault();
}
  
const useStyles = makeStyles({
    depositContext: {
      flex: 1,
    },
});

// Resolves charts dependancy
charts(FusionCharts);

const dataSource = {
  chart: {
    caption: "Top 5 countries with Global Oil Reserves",
    subcaption: "MMbbl= One Million barrels",
    enablesmartlabels: "1",
    showlabels: "1",
    numbersuffix: " MMbbl",
    usedataplotcolorforlabels: "1",
    plottooltext: "$label, <b>$value</b> MMbbl",
    theme: "fusion"
  },
  data: [
    {
      label: "Venezuela",
      value: "290"
    },
    {
      label: "Saudi",
      value: "260"
    },
    {
      label: "Canada",
      value: "180"
    },
    {
      label: "Iran",
      value: "140"
    },
    {
      label: "Russia",
      value: "115"
    }
  ]
};
  
const pieChart = ()=>{
    //const classes = useStyles();
    return (
        <React.Fragment>
            <Title>최근 구매 현황</Title>
        </React.Fragment>
    );
};

export default pieChart;